import React, { useState, createContext, useEffect, useRef } from 'react'
import "../style/blog.less"
import { navigate, graphql } from "gatsby"
import listImg1 from "../images/blog/sight1.png"
import listImg2 from "../images/blog/sight2.png"
import listImg3 from "../images/blog/sight3.png"
import listImg4 from "../images/blog/sight4.png"
import listImg5 from "../images/blog/blog_list_img5.png"
import listImg6 from "../images/blog/blog_list_img6.png"
import listImg7 from "../images/blog/blog_list_img7.png"
import listImg8 from "../images/blog/blog_list_img8.png"
import listImg9 from "../images/blog/blog_list_img9.png"
import listImg10 from "../images/blog/blog_list_img10.png"
import listImg11 from "../images/blog/blog_list_img11.png"
import listImg12 from "../images/blog/blog_list_img12.png"
import listImg13 from "../images/blog/blog_list_img13.png"
import listImg14 from "../images/blog/blog_list_img14.png"
import listImg15 from "../images/blog/blog_list_img15.png"
import Header from '../components/header'
import Footer from "../components/footer"
export default function Blog({ data }) {
    const [num, setNum] = useState(0)
    const [titleShadow, setTitleShadow] = useState(false)
    const blogTitle = useRef(null)
    const blogList = [
        { attribute: "Case Studies", img: listImg1, title: "The Rise Of Ransomware Attacks", text: "The rise of ransomware attacks in the last year is due to various factors. These include the popularity of difficult-to-trace cryptocurrency, a boom in WFH (work-from-home) practices that have created new vulnerabilities, and a tense political climate between America and Russia. Read More" },
        { attribute: "Insights", img: listImg2, title: "Canada-Winning the fight for Data Sovereignty could be our last chance in a world-class innovation economy", text: "Data sovereignty has become a topic known to Canadian policy makers for some time.  Read More" },
        { attribute: "Product", img: listImg3, title: "Data Assets - the new asset class for everyone", text: "The exhaust produced by our interaction with digital systems makes us part of the data economy, just as the energy we consume, the food we eat, and the breath we exhale make us effectively part of the carbon economy.  Read More" },
        { attribute: "Case Studies", img: listImg4, title: "Multi-cloud, the next State of Enterprise Computing", text: "Cloud infrastructure has dominated the last decade of enterprise computing. However, Cloud’s success has left unintended and intended consequences in its wake.  Read More" }
        // { id: 4, img: listImg5 ,title:"Canada-Winning the fight for Data Sovereignty could be ...",text:"Data sovereignty has become a topic known to Canadian policy makers for some time.  Data sovereignty has become a topic..." },
        // { id: 5, img: listImg6 ,title:"Canada-Winning the fight for Data Sovereignty could be ...",text:"Data sovereignty has become a topic known to Canadian policy makers for some time.  Data sovereignty has become a topic..." },
        // { id: 6, img: listImg7 ,title:"Canada-Winning the fight for Data Sovereignty could be ...",text:"Data sovereignty has become a topic known to Canadian policy makers for some time.  Data sovereignty has become a topic..." },
        // { id: 7, img: listImg8 ,title:"Canada-Winning the fight for Data Sovereignty could be ...",text:"Data sovereignty has become a topic known to Canadian policy makers for some time.  Data sovereignty has become a topic..." },
        // { id: 8, img: listImg9 ,title:"Canada-Winning the fight for Data Sovereignty could be ...",text:"Data sovereignty has become a topic known to Canadian policy makers for some time.  Data sovereignty has become a topic..." },
        // { id: 9, img: listImg10 ,title:"Canada-Winning the fight for Data Sovereignty could be ...",text:"Data sovereignty has become a topic known to Canadian policy makers for some time.  Data sovereignty has become a topic..." },
        // { id: 10, img: listImg11 ,title:"Canada-Winning the fight for Data Sovereignty could be ...",text:"Data sovereignty has become a topic known to Canadian policy makers for some time.  Data sovereignty has become a topic..." },
        // { id: 11, img: listImg12 ,title:"Canada-Winning the fight for Data Sovereignty could be ...",text:"Data sovereignty has become a topic known to Canadian policy makers for some time.  Data sovereignty has become a topic..." },
        // { id: 12, img: listImg13 ,title:"Canada-Winning the fight for Data Sovereignty could be ...",text:"Data sovereignty has become a topic known to Canadian policy makers for some time.  Data sovereignty has become a topic..." },
        // { id: 13, img: listImg14 ,title:"Canada-Winning the fight for Data Sovereignty could be ...",text:"Data sovereignty has become a topic known to Canadian policy makers for some time.  Data sovereignty has become a topic..." },
        // { id: 14, img: listImg15 ,title:"Canada-Winning the fight for Data Sovereignty could be ...",text:"Data sovereignty has become a topic known to Canadian policy makers for some time.  Data sovereignty has become a topic..." },
    ]
    const [list, setList] = useState(blogList)
    const classification = [
        "All",
        "Product",
        "Insights",
        "External",
        "Case Studies",
    ]
    let arr = []
    const screenList = (item, index) => {
        arr = []
        setNum(index)
        blogList.forEach((data, idx) => {
            if (data.attribute === item) {
                arr.push(data)
                setList(arr)
            } else if (index === 0) {
                setList(blogList)
            } else if (data.attribute !== item) {
                setList(arr)
            }
        })
    }
    useEffect(() => {
        const observer = new IntersectionObserver(entries => {
            setTitleShadow(entries[0].isIntersecting)
        }, { threshold: [1] })
        observer.observe(blogTitle.current)
    })

    return (
        <div className='blog'>
            <Header />
            <h1 ref={blogTitle} className={titleShadow ? "shadow" : ""}>Blog</h1>
            <h3>Learn how to use our platform to power your business</h3>
            <div>
                <ul className='classification'>
                    {
                        classification.map((item, index) => {
                            return (
                                <li onClick={() => screenList(item, index)} className={num === index ? "active" : ""} key={index}>{item}</li>
                            )
                        })
                    }
                </ul>
            </div>
            <ul className='blog_list'>
                {
                    list.map((item, index) => {
                        return (
                            <li onClick={() => navigate("/blogdetail" + `?id=${index}`)} key={index}>
                                <div><img src={item.img} alt="" /></div>
                                <div className='blog_list_content'>
                                    <h6>{item.attribute}</h6>
                                    <h3>{item.title}</h3>
                                    <p>{item.text}</p>
                                </div>
                            </li>
                        )
                    })
                }
            </ul>
            <Footer />
        </div>
    )
}
